import {Link} from "react-router-dom";

const ScrollStatsPage = () => {
    return(
        <>
            <Link style={{marginRight: '10px'}} to={'/stats/scroll/table'}>Статистика</Link>
            <Link to={'/stats/scroll/marks'}>Марки</Link>
        </>
    )
}

export default ScrollStatsPage