import EnhancedTableHead from "../header";
import {
    Table,
    TableBody,
    TableCell as MuiTableCell,
    TableContainer,
    TableRow
} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import styled from "styled-components";
import axios from "axios";
import Spinner from "../../../../modules/LoadingSpinner/spinner.component";

const StyledTableContainer = styled(TableContainer)(() => ({
    width: 'auto',  // Установите ширину, чтобы она автоматически подстраивалась
    overflowX: 'auto',  // Включаем горизонтальную прокрутку
}));

const airdropData = {
    scroll: [
        'tx3',
        'tx4',
    ],
    zksync: [
        'tx3',
    ],
    base: [
        'tx2'
    ],
    zora: [
        'tx2'
    ]
}

const StyledTableCellPriority = styled(MuiTableCell)`
    background-color: #fce4a3;
    color: #000000;
    border-bottom: 1px solid #777;
    position: sticky;
    border-bottom: 1px solid #777;
    padding: 4px 8px;
    border-right: 1px solid #777;

    &:last-child {
        border-right: none;
    }
`;

const StyledTableCell = styled(MuiTableCell)`
    background-color: #fdf2d0;
    color: #000000;
    border-bottom: 1px solid #777;
    border-bottom: 1px solid #777;
    padding: 4px 8px;
    border-right: 1px solid #777;
    border-left: 1px solid #777;

    &:last-child {
        border-right: none;
    }
`;

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getMaxNumericKey(obj) {
    return Math.max(
        ...Object.keys(obj)
            .map(key => Number(key))
            .filter(key => !isNaN(key))
    );
}

function EnhancedTable({ airdrop }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('totalWallets');
    const [dataApi, setDataApi] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const tables = airdropData[airdrop];

    useEffect(() => {
        const getApi = async () => {
            setIsLoading(true);

            try {
                const newApiData = {};
                for (const tableName of tables) {
                    const url = `https://api-cube3.airdrop-hunter.site/tx_data/airdrop_full_info/${airdrop}/${tableName}`;
                    const response = await axios.get(url);
                    newApiData[tableName] = response.data.result;
                }
                setIsLoading(false);
                setDataApi(newApiData);
            } catch (e) {
                console.log(e);
            }
        };

        getApi();
    }, [airdrop, tables]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const txRowReturner = (tableName, txsName, index) => {
        return (
            <TableRow key={`${tableName}_${txsName}_${index}`}>
                <StyledTableCellPriority>{txsName}</StyledTableCellPriority>
                <StyledTableCell>{dataApi[tableName]?.txs[txsName]?.totalWals}</StyledTableCell>
                <StyledTableCell>{dataApi[tableName]?.txs[txsName]?.totalTx}</StyledTableCell>
                <StyledTableCell>{dataApi[tableName]?.txs[txsName]?.totalSuccessTx}</StyledTableCell>
                <StyledTableCell>{((dataApi[tableName]?.txs[txsName]?.totalSuccessTx / dataApi[tableName]?.txs[txsName]?.totalTx) * 100).toFixed(2)}</StyledTableCell>
                <StyledTableCell>{dataApi[tableName]?.txs[txsName]?.totalErroredTx}</StyledTableCell>
                <StyledTableCell>{((dataApi[tableName]?.txs[txsName]?.totalErroredTx / dataApi[tableName]?.txs[txsName]?.totalTx) * 100).toFixed(2)}</StyledTableCell>
            </TableRow>
        )
    }

    if (isLoading) {
        return (
            <Spinner />
        )
    } else {
        return (
            <StyledTableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {Object.keys(dataApi).map((tableName, index) => {
                            const txsArr = Object.keys(dataApi[tableName]?.txs).filter((a) => a !== 'total');

                            return (
                                <Fragment key={`${tableName}_${index}`}>
                                    <TableRow key={`${tableName}_${index}_row_total`}>
                                        <StyledTableCellPriority style={{ borderTop: '2px solid #777' }} rowSpan={txsArr.length + 1}>
                                            {`${capitalizeFirstLetter(airdrop)}C${tableName.slice(2)}`}
                                        </StyledTableCellPriority>
                                        <StyledTableCellPriority style={{ borderTop: '2px solid #777' }}>По кругу</StyledTableCellPriority>
                                        <StyledTableCell style={{ borderTop: '2px solid #777' }}>{dataApi[tableName]?.txs?.total?.totalWals}</StyledTableCell>
                                        <StyledTableCell style={{ borderTop: '2px solid #777' }}>{dataApi[tableName]?.txs?.total?.totalTx}</StyledTableCell>
                                        <StyledTableCell style={{ borderTop: '2px solid #777' }}>{dataApi[tableName]?.txs?.total?.totalSuccessTx}</StyledTableCell>
                                        <StyledTableCell style={{ borderTop: '2px solid #777' }}>{((dataApi[tableName]?.txs?.total?.totalSuccessTx / dataApi[tableName]?.txs?.total?.totalTx) * 100).toFixed(2)}</StyledTableCell>
                                        <StyledTableCell style={{ borderTop: '2px solid #777' }}>{dataApi[tableName]?.txs?.total?.totalErroredTx}</StyledTableCell>
                                        <StyledTableCell style={{ borderTop: '2px solid #777' }}>{((dataApi[tableName]?.txs?.total?.totalErroredTx / dataApi[tableName]?.txs?.total?.totalTx) * 100).toFixed(2)}</StyledTableCell>
                                        <StyledTableCell style={{ borderTop: '2px solid #777' }} rowSpan={txsArr.length + 1}>{dataApi[tableName]?.activity?.circleSteps}</StyledTableCell>
                                        <StyledTableCell style={{ borderTop: '2px solid #777' }} rowSpan={txsArr.length + 1}>{dataApi[tableName]?.activity?.avgDaysToComplete}</StyledTableCell>
                                        <StyledTableCell style={{ borderTop: '2px solid #777' }} rowSpan={txsArr.length + 1}>{dataApi[tableName]?.activity?.avgTxsPerDay}</StyledTableCell>
                                        <StyledTableCell style={{ borderTop: '2px solid #777' }} rowSpan={txsArr.length + 1}>{dataApi[tableName]?.activity?.maxDaysToComplete}</StyledTableCell>
                                        <StyledTableCell style={{ borderTop: '2px solid #777' }} rowSpan={txsArr.length + 1}>{dataApi[tableName]?.activity?.minDaysToComplete}</StyledTableCell>
                                        <StyledTableCell style={{ borderTop: '2px solid #777' }} rowSpan={txsArr.length + 1}>{dataApi[tableName]?.activity?.avgUniqueDays}</StyledTableCell>
                                        <StyledTableCell style={{ borderTop: '2px solid #777' }} rowSpan={txsArr.length + 1}>{dataApi[tableName]?.activity?.avgUniqueMonth}</StyledTableCell>
                                        <StyledTableCell style={{ borderTop: '2px solid #777' }} rowSpan={txsArr.length + 1}>{dataApi[tableName]?.balances?.avgBalanceCoin}</StyledTableCell>
                                        <StyledTableCell style={{ borderTop: '2px solid #777' }} rowSpan={txsArr.length + 1}>{dataApi[tableName]?.balances?.avgBalanceCoinUSD}</StyledTableCell>
                                    </TableRow>
                                    {txsArr.map((row, idx) => txRowReturner(tableName, row, idx))}
                                </Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        );
    }
}

export default EnhancedTable;
