import {TableCell as MuiTableCell, TableContainer, TableHead, TableRow, TableSortLabel} from "@mui/material";
import styled from "styled-components";


const StyledTableContainer = styled(TableContainer)`
    max-width: 1200px;
    margin: 20px auto;
    background: #333; /* Темный фон */
`;

const StyledTableCell = styled(MuiTableCell)`
    background-color: #fad878;
    color: #000000;
    border-bottom: 1px solid #777; 
    border-bottom: 1px solid #777;
    padding: 4px 8px;
    border-right: 1px solid #777; 
    &:last-child {
        border-right: none; 
    }
`;

const StyledTableHead = styled(TableHead)`
    ${StyledTableCell} {
        background-color: #fad878; /* Чуть светлее цвет для заголовка */
        border-bottom: 2px solid rgba(159, 136, 78, 0.71); /* Более толстая граница для заголовка */
        border-right: 1px solid #c2a65f;
        text-align: center;
        font-weight: bold;
        padding: 4px 8px;
        
        &:last-child {
            border-right: none;
        }
    }
`;

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #444; /* Чередование цветов строк */
  }
  &:hover {
    background-color: #555; /* Цвет при наведении */
  }
`;

const columns = [
    { id: 'groupName', label: 'Название круга', disableSorting: true },
    { id: 'step', label: 'Шаг', disableSorting: false },
    { id: 'totalWallets', label: 'Всего кошельков', disableSorting: false },
    { id: 'totalTxs', label: 'Всего транзакций', disableSorting: false },
    { id: 'totalSuccessTxs', label: 'Всего успешных транзакций', disableSorting: false },
    { id: 'percentSuccessTxs', label: '% успешных', disableSorting: false },
    { id: 'totalErroredTxs', label: 'Всего транзакций с ошибкой', disableSorting: false },
    { id: 'percentErroredTxs', label: '% ошибочных', disableSorting: false },
    { id: 'circleTxs', label: 'Кол-во транзакций на кругу', disableSorting: false },
    { id: 'avgDaysToComplete', label: 'Среднее кол-во дней для завершения', disableSorting: false },
    { id: 'avgTxsPerDay', label: 'Среднее кол-во транзакций в день', disableSorting: false },
    { id: 'maxDaysToComplete', label: 'Макс. кол-во дней для завершения', disableSorting: false },
    { id: 'minDaysToComplete', label: 'Мин. кол-во дней для завершения', disableSorting: false },
    { id: 'avgUniqueDays', label: 'Среднее кол-во уникальных дней', disableSorting: false },
    { id: 'avgUniqueMonth', label: 'Среднее кол-во уникальных месяцев', disableSorting: false },
    { id: 'avgCoinBalance', label: 'Средний баланс Coin', disableSorting: false },
    { id: 'avgCoinBalanceUSD', label: 'Средний баланс Coin в USD', disableSorting: false },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <StyledTableHead>
            <TableRow>
                <StyledTableCell colSpan={3}>
                    Краточки/Круги/Шаги
                </StyledTableCell>
                <StyledTableCell colSpan={6}>
                    Транзакции
                </StyledTableCell>
                <StyledTableCell colSpan={7}>
                    Активность на кругу
                </StyledTableCell>
                <StyledTableCell colSpan={2}>
                    Баланс
                </StyledTableCell>
            </TableRow>
            <TableRow>
                {columns.map((column) => (
                    <StyledTableCell
                        key={column.id}
                        sortDirection={orderBy === column.id ? order : false}
                    >
                        {column.disableSorting ? (
                            column.label
                        ) : (
                            <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'asc'}
                                onClick={createSortHandler(column.id)}
                            >
                                {column.label}
                            </TableSortLabel>
                        )}
                    </StyledTableCell>
                ))}
            </TableRow>
        </StyledTableHead>
    );
}

export default EnhancedTableHead;