import './App.css';
import MainPage from "./Pages/MainPage/MainPage.page";
import LayOutModule from "./modules/LayOut/index.module";
import {useDispatch, useSelector} from "react-redux";
import styled, {ThemeProvider} from "styled-components";
import {darkTheme, lightTheme, ThemeContext} from "./theming";
import {selectTheme} from "./store/theme/theme-selector";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import StatsPage from "./Pages/StatsPage/StatsPage.page";
import ErrorPage from "./Pages/ErrorPage/MainPage.page";
import EywaStatsPage from "./Pages/StatsPage/eywa/index.component";
import EywaStatsMessage from "./Pages/StatsPage/eywa/pages/eywa_stats_message.page";
import EywaStyledTable from "./Pages/StatsPage/eywa/pages/stats_table/eywa_stats_table.page";
import TableStats from "./Pages/StatsPage/statsByAirdrop/table.page";
import ScrollStatsPage from "./Pages/StatsPage/scroll/index.page";
import ScrollTableStats from "./Pages/StatsPage/scroll/TableStatsScroll.components";
import ScrollStyledTable from "./Pages/StatsPage/scroll/scroll_marks_table.page";


const AppWrapper = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
  background: ${(props) => props.theme.background.background};
  min-height: 100vh;
`

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainPage />,
        errorElement: <ErrorPage />
    },
    {
        path: "stats",
        element: <StatsPage />,
    },
    {
        path: "stats/eywa",
        element: <EywaStatsPage />,
    },
    {
        path: "stats/eywa/message",
        element: <EywaStatsMessage />,
    },
    {
        path: "stats/eywa/table",
        element: <EywaStyledTable />,
    },
    {
        path: "stats/table/:airdrop",
        element: <TableStats />,
    },
    {
        path: "stats/table/scroll",
        element: <ScrollStatsPage />,
    },
    {
        path: "stats/scroll/marks",
        element: <ScrollStyledTable />,
    },
    {
        path: "stats/scroll/table",
        element: <ScrollTableStats />,
    },
]);

function App() {
    const dispatch = useDispatch();

    const currentThemeText = useSelector(selectTheme);

    const currentTheme = currentThemeText === 'light' ? lightTheme : darkTheme;

    return (
        <ThemeProvider theme={currentTheme}>
            <ThemeContext.Provider value={currentTheme}>
                <AppWrapper>
                    <LayOutModule>
                        <RouterProvider router={router} />
                    </LayOutModule>
                </AppWrapper>
            </ThemeContext.Provider>
        </ThemeProvider>
    );
}

export default App;
