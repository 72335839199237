import EnhancedTable from "../statsByAirdrop/bodyTable";
import CenterTitle from "../../../modules/CenterTitle/index.component";

function ScrollTableStats() {
    return(
        <>
            <CenterTitle aciveTab={'Stats'} />
            <div style={{marginTop: '10px'}}>
                <EnhancedTable airdrop={'scroll'} />
            </div>
        </>
    )
}

export default ScrollTableStats;