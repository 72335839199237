import TopInfo from "./components/TopInfo/index.component";
import {LayOutContainer} from "./index.style";
import TopHeaderComponent from "./components/TopHeader/index.component";
import {useDispatch} from "react-redux";
import Spinner from "../LoadingSpinner/spinner.component";

const LayOutModule = ({ children }) => {
    const dispatch = useDispatch();

    const isLoading = false;

    if (isLoading) {
        return(<Spinner />)
    } else {
        return (
            <LayOutContainer>
                <TopInfo/>
                <TopHeaderComponent/>
                {children}
            </LayOutContainer>
        )
    }

}

export default LayOutModule;
