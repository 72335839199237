import {
    TopHeaderContainer,
    TopHeaderLeftSideContainer,
    TopHeaderRightSideContainer, TopHeaderRightSideWrapper,
    TopHeaderWrapper
} from "./index.style";
import LogoButtonComponent from "../LogoButton/index.component";
import MenuButton from "../../../../ui/components/Buttons/MenuButton/index.component";

const TopHeaderComponent = () => {

    return(
        <TopHeaderContainer>
            <TopHeaderWrapper>
                <TopHeaderLeftSideContainer>
                    <LogoButtonComponent/>
                </TopHeaderLeftSideContainer>
                <TopHeaderRightSideContainer>
                    <TopHeaderRightSideWrapper>
                        <MenuButton/>
                    </TopHeaderRightSideWrapper>
                </TopHeaderRightSideContainer>
            </TopHeaderWrapper>
        </TopHeaderContainer>
    )
}

export default TopHeaderComponent;
