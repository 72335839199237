import {
    CenterTitleCenter,
    CenterTitleContainer,
    CenterTitleTextCurrentStyle,
    CenterTitleTextStyle
} from "./index.style";
import {Link} from "react-router-dom";

const CenterTitle = ({aciveTab}) => {

    if (aciveTab === 'Home') {
        return(
            <CenterTitleCenter>
                <CenterTitleContainer>
                    <CenterTitleTextCurrentStyle to={'/'}>
                        Home
                    </CenterTitleTextCurrentStyle>
                    <CenterTitleTextStyle to={'/airdrops'}>
                        Airdrops
                    </CenterTitleTextStyle>
                    <CenterTitleTextStyle to={'/stats'}>
                        Stats
                    </CenterTitleTextStyle>
                </CenterTitleContainer>
            </CenterTitleCenter>
        )
    }

    if (aciveTab === 'Airdrops') {
        return(
            <CenterTitleCenter>
                <CenterTitleContainer>
                    <CenterTitleTextStyle to={'/'}>
                        Home
                    </CenterTitleTextStyle>
                    <CenterTitleTextCurrentStyle to={'/airdrops'}>
                        Airdrops
                    </CenterTitleTextCurrentStyle>
                    <CenterTitleTextStyle to={'/stats'}>
                        Stats
                    </CenterTitleTextStyle>
                </CenterTitleContainer>
            </CenterTitleCenter>
        )
    }

    if (aciveTab === 'Stats') {
        return(
            <CenterTitleCenter>
                <CenterTitleContainer>
                    <CenterTitleTextStyle to={'/'}>
                        Home
                    </CenterTitleTextStyle>
                    <CenterTitleTextStyle to={'/airdrops'}>
                        Airdrops
                    </CenterTitleTextStyle>
                    <CenterTitleTextCurrentStyle to={'/stats'}>
                        Stats
                    </CenterTitleTextCurrentStyle>
                </CenterTitleContainer>
            </CenterTitleCenter>
        )
    }

    return(
        <CenterTitleCenter>
            <CenterTitleContainer>
                <CenterTitleTextStyle to={'/'}>
                    Home
                </CenterTitleTextStyle>
                <CenterTitleTextStyle to={'/airdrops'}>
                    Airdrops
                </CenterTitleTextStyle>
                <CenterTitleTextStyle to={'/stats'}>
                    Stats
                </CenterTitleTextStyle>
            </CenterTitleContainer>
        </CenterTitleCenter>
    )

}

export default CenterTitle;
