import styled from "styled-components";
import {TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import React from "react";

const headers = [
    { id: 'realId', label: 'Id' },
    { id: 'wal', label: 'Wal' },
    { id: 'coin_usd', label: 'Coin USD' },
    { id: 'stable', label: 'Stable' },
    { id: 'total_marks', label: 'Total Marks' },
];

const StyledTableRow = styled(TableRow)`
    background-color: #424242;
`;

const StyledTableSortLabel = styled(TableSortLabel)`
    color: #ffffff;
`;

const StyledText = styled('div')`
    color: #ffffff;
`;

const TableHeaderScroll = ({ order, orderBy, onRequestSort }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <StyledTableRow>
                {headers.map((header) => (
                    <TableCell
                        key={header.id}
                        sortDirection={orderBy === header.id ? order : false}
                    >
                        <StyledTableSortLabel
                            active={orderBy === header.id}
                            direction={orderBy === header.id ? order : 'asc'}
                            onClick={createSortHandler(header.id)}
                        >
                            <StyledText>
                                {header.label}
                            </StyledText>
                        </StyledTableSortLabel>
                    </TableCell>
                ))}
            </StyledTableRow>
        </TableHead>
    );
};

export default TableHeaderScroll;