import {Link} from "react-router-dom";

const EywaStatsPage = () => {
    return(
        <>
            <Link to={'/stats/eywa/message'}>Краткая статистика</Link>
            <Link to={'/stats/eywa/table'}>Полная таблица овнеров</Link>
        </>
    )
}

export default EywaStatsPage