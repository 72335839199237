import { styled } from '@mui/material/styles';
import {Button, Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableRow} from "@mui/material";
import {useEffect, useState} from "react";
import axios from "axios";
import CenterTitle from "../../../modules/CenterTitle/index.component";
import TableHeaderScroll from "./header.component";
import {CSVLink} from "react-csv";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledTableContainer = styled(TableContainer)`
    margin-top: 20px;
`;

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

const sortedRowInformation = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

const summMarks = (apiData) => {
    return apiData.reduce((acc, el) => acc + el.total_marks, 0)
}

const ScrollStyledTable = () => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');

    const [apiResult, setApiResult] = useState([]);

    useEffect( () => {
        let index = 0;

        const getApi = async () => {
            const response = await axios.get('https://api-cube.airdrop-hunter.site/tx_data/scroll_marks')

            const oldResult = response.data.result;

            setApiResult(oldResult.map((walData) => {
                index++;

                return {
                    ...walData,
                    realId: index
                }
            }));
        }

        getApi();
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedData = sortedRowInformation(apiResult, getComparator(order, orderBy));

    const dataArrForCsv = sortedData.map(walData => {
        return [
            walData.realId,
            walData.wal,
            walData.coin_usd,
            walData.stable,
            walData.total_marks,
        ]
    })

    const csvData = [
        ['Id', 'Wal', 'Coin USD' , 'Stable' , 'Total Marks'],
        ...dataArrForCsv
    ]


    return (
        <>
            <CenterTitle aciveTab={'Stats'} />


            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                style={{marginBottom: '10px'}}
            >
                <CSVLink
                    filename={"scroll-marks-data.csv"}
                    data={csvData}
                    style={{
                        textDecoration: 'none',
                        color: 'white'
                    }}
                >
                    Download CSV
                </CSVLink>
            </Button>

            <div>
                Total Marks: {summMarks(sortedData)}
            </div>

            <StyledTableContainer style={{marginTop: '10px'}} component={Paper}>
                <Table aria-label="customized table">
                    <TableHeaderScroll order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                    <TableBody>
                        {sortedData.map((row, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell>{row.realId}</StyledTableCell>
                                <StyledTableCell>{row.wal}</StyledTableCell>
                                <StyledTableCell>{row.coin_usd}</StyledTableCell>
                                <StyledTableCell>{row.stable}</StyledTableCell>
                                <StyledTableCell>{row.total_marks}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </>
    );
};

export default ScrollStyledTable;
