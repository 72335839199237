import {MenuButtonContainer, MenuButtonSvg, MenuButtonWrapper} from "./index.style";
import {useState} from "react";
import MenuDropDownMenuComponent from "./MenuDropDownMenu/index.component";

const MenuButton = () => {
    const [isDropdown, setIsDropDown] = useState(false);
    const [openModalConnectWallet, setOpenModalConnectWallet] = useState(false);
    const [openModalReferral, setOpenModalReferral] = useState(false);

    return(
        <>
            <MenuButtonContainer>
                <MenuButtonWrapper onClick={() => setIsDropDown(!isDropdown)}>
                    <MenuButtonSvg/>
                </MenuButtonWrapper>
            </MenuButtonContainer>
            { isDropdown ?
                <MenuDropDownMenuComponent
                    setIsDropDownOpen={setIsDropDown}
                    setOpenModalReferral={setOpenModalReferral}
                    setOpenModalConnectWallet={setOpenModalConnectWallet}
                /> :
                <></>
            }
        </>
    )
}

export default MenuButton;
