import CenterTitle from "../../modules/CenterTitle/index.component";
import {MainContainer} from "./MainPage.style";
import {TitleBL} from "../../ui/components/Titles/Titles.style";

const ErrorPage = () => {
    return(
        <>
            <CenterTitle />
            <MainContainer>
                <TitleBL>
                   ERROR 404 NOT FOUND
                </TitleBL>
            </MainContainer>
        </>
    )
}

export default ErrorPage;
