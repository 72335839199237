import EnhancedTable from "./bodyTable";
import CenterTitle from "../../../modules/CenterTitle/index.component";
import {useParams} from "react-router-dom";

function TableStats() {
    const { airdrop } = useParams();

    return(
        <>
            <CenterTitle aciveTab={'Stats'} />
            <div style={{marginTop: '10px'}}>
                <EnhancedTable airdrop={airdrop} />
            </div>
        </>
    )
}

export default TableStats;