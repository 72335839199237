import axios from "axios";
import {useEffect, useState} from "react";

const EywaStatsMessage = () => {
    const date = new Date();

    const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear() % 2000}`;

    const [apiResult, setApiResult] = useState({});

    useEffect( () => {
        const getApi = async () => {
            const response = await axios.get('https://api-cube.airdrop-hunter.site/tx_data/get_tvl')

            setApiResult(response.data.result);
        }

        getApi();
    }, []);

    const {
        total_balance_tkn,
        swaps,
        total_tx,
        total_wals,
        total_wals_started,
        total_wals_started_step6_status1,
        total_completed_wal
    } = apiResult;

    return(
        <div style={{display: 'block'}}>
            <div>
                CТАТИСТИКА ПО КАРТОЧКЕ EYWA от AIRDROP HUNTER
            </div>
            <br/>
            <div>
                {formattedDate}
            </div>
            <br/>
            <div>
                сколько кошельков у нас куплено: {total_wals}
            </div>
            <div>
                сколько кошельков у нас пополнено: {total_wals_started}
            </div>
            <div>
                сколько запущенно (те что сделали минимум 1 бридж): {total_wals_started_step6_status1}
            </div>
            <div>
                сколько уже полностью прошли круг: {total_completed_wal}
            </div>
            <div>
                какой объём мы уже сделали: {parseFloat(total_balance_tkn).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, ' ')}$
            </div>
            <div>
                Всего бриджей: {swaps}
            </div>
        </div>
    )
}

export default EywaStatsMessage