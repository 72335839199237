import styled from "styled-components";
import {Link} from "react-router-dom";

export const TitleBL = styled.div`
    margin-bottom: 27px;
    margin-top: 35px;
    font-size: 24px;
    line-height: 29px;
    color: ${(props) => props.theme.colors.text};
`

export const LinkStyled = styled(Link)`
    display: inline-block;
    line-height: 19px;
    color: #b2b2b2;
    padding: 6px 18px 8px;
    cursor: pointer;
`
