import React from 'react';
import styled from 'styled-components';
import {TableCell, TableHead, TableRow} from "@mui/material";

const headers = [
    { id: 'owner', label: 'Owner' },
    { id: 'total_wals', label: 'Total Wals' },
    { id: 'wal', label: 'Wal' },
    { id: 'coin_usd', label: 'Coin USD' },
    { id: 'stable', label: 'Stable' },
    { id: 'total_points', label: 'Total Points' },
    { id: 'total_swaps', label: 'Total Swaps' },
    { id: 'total_tvl', label: 'Total TVL' },
];

const StyledTableRow = styled(TableRow)`
    background-color: #424242;
`;

const StyledTableSortLabel = styled(TableRow)`
    color: #ffffff;
`;

const StyledText = styled('div')`
    color: #ffffff;
`;

const TableHeader = ({ order, orderBy, onRequestSort }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <StyledTableRow>
                {headers.map((header) => (
                    <TableCell key={header.id}>
                        <StyledTableSortLabel
                            active={orderBy === header.id}
                            direction={orderBy === header.id ? order : 'asc'}
                            onClick={createSortHandler(header.id)}
                        >
                            <StyledText>
                                {header.label}
                            </StyledText>
                        </StyledTableSortLabel>
                    </TableCell>
                ))}
            </StyledTableRow>
        </TableHead>
    );
};

export default TableHeader;
