import CenterTitle from "../../modules/CenterTitle/index.component";
import {MainContainer} from "./StatsPage.style";
import {LinkStyled, TitleBL} from "../../ui/components/Titles/Titles.style";

const StatsPage = () => {
    return(
        <>
            <CenterTitle aciveTab={'Stats'} />
            <MainContainer>
                <TitleBL>
                    <LinkStyled to={'/stats/eywa'}>Eywa</LinkStyled>
                    <LinkStyled to={'/stats/table/scroll'}>Scroll</LinkStyled>
                    <LinkStyled to={'/stats/table/zksync'}>ZkSync</LinkStyled>
                    <LinkStyled to={'/stats/table/base'}>Base</LinkStyled>
                    <LinkStyled to={'/stats/table/zora'}>Zora</LinkStyled>
                </TitleBL>
            </MainContainer>
        </>
    )
}

export default StatsPage;
